import React, { useContext, useEffect, useState } from "react";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../AppContext";
import { ASSET_URL } from "../../utils/AppUtil";

type MemoryProps = {
    title: string,
    message: string,
    images: string[] | undefined
}

export const MemoryDetailed: React.FC<MemoryProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Images array state
    const [memoryImages, setMemoryImages] = useState<string[]>([]);
    const [currentBarIndex, setCurrentBarIndex] = useState(0);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    useEffect(() => {
        if (props.images) {
            setMemoryImages(props.images);
        }
    }, [props.images]);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsTransitioning(true);
            setCurrentBarIndex((prevIndex) => (prevIndex + 1) % memoryImages.length);
            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % memoryImages.length);
                setIsTransitioning(false);
            }, 1000); // Duration of the fade-in transition
        }, 5000);

        return () => clearInterval(interval);
    }, [memoryImages]);

    return (
        <div className="modal-life-event modal fade" id="MemoryDetailed" tabIndex={999} aria-labelledby="MemoryDetailed" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen" style={{zIndex: "999", backgroundColor: "#00222F"}}>
                <div className="modal-content" style={{backgroundColor: "#00222F"}}>
                    <div className="modal-body p-0" style={{scrollbarWidth: "none", backgroundColor: "#00222F"}}>

                        <div className="memory-bars">
                            {memoryImages.map((_, index) => (
                                <div key={index} className={`memory-bar ${index <= currentBarIndex ? 'active' : ''}`} 
                                />
                            ))}
                        </div>

                        <div className="memory-content-top p-3">
                            <div className="d-flex align-items-center gap-3">
                                <img src="/images/fl-avatar-min.svg" width={36} height={36} />
                                <div className="memory-name d-flex flex-column gap-1">
                                    <p>A memory from</p>
                                    <p>{commonData.firstName} {commonData.lastName}</p>
                                </div>
                            </div>

                            <img src="/images/memory-modal-close.svg" data-bs-dismiss="modal" aria-label="Close" />
                        </div>

                        <div className="memory-content-bottom p-4">
                            <div className="memory-text d-flex flex-column gap-4 pe-3">
                                <p>{props.title}</p>
                                <p>{props.message}</p>
                            </div>
                            <div className="d-flex flex-column gap-4">
                                <img src="/images/memory-edit-btn.svg" />
                                <img src="/images/memory-share-btn.svg" />
                                <img src="/images/memory-audio-btn.svg" />
                            </div>
                        </div>

                        <div className="memory-images-wrapper">
                            {memoryImages.map((image, index) => (
                                <img
                                    key={index}
                                    className={`memory-background-img ${index === currentImageIndex ? 'fade-in' : 'hidden'} ${isTransitioning && index === (currentImageIndex + 1) % memoryImages.length ? 'fade-in-next' : ''}`}
                                    src={`${ASSET_URL}/public/picture/${image}`}
                                />
                            ))}
                        </div>

                    </div>
                
                </div>
            </div>
        </div>
    );
}