import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { MemorialTab, MemorialType, PageResultType } from "../AppType";
import { useTranslation } from "react-i18next";
import { fetchRecords, debug, ASSET_URL } from "../../utils/AppUtil";
import { AppContext } from "../../AppContext";
import { UserAccount } from "../Modals/ModalUserAccount";
import { ModalCreateMemorial } from "../Modals/ModalCreateMemorial";
import { AddToHome } from "../Modals/ModalAddToHome";
import { MemorialContext } from "../../MemorialContext";
import 'animate.css';

type HomeProps = {
    activeTab: MemorialTab,
    setActiveTab: Dispatch<SetStateAction<MemorialTab>>,
    isActiveTab:  (pageId: MemorialTab) => number,
    handleNavClick: (pageId: MemorialTab) => void
}

export const Home: React.FC<HomeProps> = (props) => {
    
    // Internationalisation
    const { t, i18n } = useTranslation();

    // Authentication data from AppContext
    const { commonData } = useContext(AppContext)!;
    
    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;
    
    const [memorials, setMemorials] = useState<React.ReactElement[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [emptyState, setEmptyState] = useState<boolean>(false)
    
    const [language, setLanguage] = useState<string>("en");
    
    const gbFlag: string = "/images/flags/flag-gb.svg";
    const frFlag: string = "/images/flags/flag-fr.svg";
    const [currentFlag, setCurrentFlag] = useState(gbFlag);

    const [splashShow, setSplashShow] = useState(false);
    const [splashAnimation, setSplashAnimation] = useState('');

    const changeLanguage = () => {
        if(language === "en") {
            setLanguage("fr");
            setCurrentFlag(frFlag)
            i18n.changeLanguage("fr");
        } else {
            setLanguage("en");
            setCurrentFlag(gbFlag)
            i18n.changeLanguage("en");
        }
    }

    // HOMEPAGE DISPLAY ----------
    useEffect(() => {

        fetchRecords(commonData.token, 'memorial/homepage', processMemorialData)
        .catch((error) => {
            setIsLoading(false);
            debug('fetchRecords error: ' + error, 'Home');
        });

    }, [memorialData, language]);

    // Simulate click to combat mobile touch swipe bug
    useEffect(() => {
        document.getElementById("discoverCommunitiesSlide1")?.click();
    }, []);

    const processMemorialData = (responseJson: PageResultType) => {
        setIsLoading(true);
        setMemorials(buildMemorialsJSX(responseJson));
        setIsLoading(false);

        if (responseJson.numRecords === 0) {
            setEmptyState(true);
        }
    }

    const buildMemorialsJSX = (response: PageResultType): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;

        // let memorialWidth: string = "calc(100vw - 64px)";
        // if (response.numRecords === 1) {
        //     memorialWidth = "calc(100vw - 32px)";
        // }

        response.data.forEach((memorial: MemorialType) => {

            let profilePictureSrc = "images/fl-avatar-min.svg";
            if (memorial.memorialPictureId) {
                profilePictureSrc = `${ASSET_URL}/public/picture/${memorial.memorialPictureId}`
            }

            let backgroundImg = "/images/covor_photo_default_Small.png";
            if (memorial.coverPictureId) {
                backgroundImg = `${ASSET_URL}/public/picture/${memorial.coverPictureId}`
            }

            result.push(

                <div key={key} className="home-memorial-tile" style={{cursor: "pointer"}}>

                    {memorial.role != "VIEWER" && 
                        <div className="home-tile-badge">
                            {memorial.role === "CREATOR" &&
                                <p>{t("Home.Memorials.roleOwner", {ns: "components"})}</p>
                            }
                            {memorial.role === "ADMIN" &&
                                <p>{t("Home.Memorials.roleAdmin", {ns: "components"})}</p>
                            }
                            {memorial.role === "EDITOR" &&
                                <p>{t("Home.Memorials.roleEditor", {ns: "components"})}</p>
                            }
                        </div>
                    }

                    <div onClick={() => {setMemorialData(memorial); props.handleNavClick(MemorialTab.tab1);}} 
                        className="home-memorial-tile-body d-flex flex-column align-items-center p-3 memorial-tile-profile-cover"
                        style={{backgroundImage: `linear-gradient(rgba(0, 34, 47, 0) -100%, rgb(6, 45, 60) 50%), url('${backgroundImg}')`}}
                    >
                        <div className="memorial-tile-profile-picture-wrapper mb-3">
                            <img src={profilePictureSrc} className="memorial-tile-profile-picture" width="148" height="148" alt="" />
                        </div>
                        <p>{memorial.firstName + ' ' + memorial.lastName}</p>
                        <p>{memorial.birthYear} - {memorial.deathYear}</p>
                    </div>
                    
                </div>
            );
            key++;
        });

        return result;
    }

    const handleSplashShow = () => {
        // setSplashAnimation('animate__animated animate__fadeIn')
        setSplashShow(true);
        setTimeout(() => {
            // setSplashAnimation('animate__animated animate__fadeOut')
            setSplashShow(false);
        }, 3000);
    }

    return (
        <>
            {splashShow &&
                <div className={`splash-loading-div ${splashAnimation}`}>
                    <img src="/images/fl-logo-small.svg" className="mb-3" width={100} height={100} alt="" />
                    <p>Forever Loved</p>
                    <p>The final resting place online</p>
                </div>
            }

            <div className="page-wrapper">

                <div className="d-flex justify-content-between p-3">
                    <UserAccount />
                    <p className="fl-txt-name" onClick={handleSplashShow}>{ t("Home.title", {ns: "components"}) }</p>
                    {/* <img src="/images/fl-bell.svg" className="home-icon-top p-2" height={40} alt="" /> */}
                    <AddToHome />
                </div>

                {emptyState &&
                    <>
                        <div className="homepage-empty-wrapper d-flex flex-column justify-content-between p-3">
                            <div className="homepage-empty-images d-flex justify-content-center">
                                <img className="px-3" src="/images/home-img-1.png" alt="" />
                                <img className="px-3 animate__animated animate__slideInRight animate__delay-2s animate__slow" src="/images/home-img-2.png" alt="" />
                                <img className="px-3 animate__animated animate__slideInLeft animate__delay-5s animate__slow" src="/images/home-img-3.png" alt="" />
                            </div>

                            <div className="homepage-empty-bottom d-flex flex-column justify-content-between gap-3">
                                <p>Memorials</p>
                                <p>Memorials remember those who have passed away. They include key life events, memories and so much more.</p>
                                {/* <button className="btn fl-btn-large mt-3" data-bs-toggle="modal" data-bs-target="#createMemorial" style={{cursor: "pointer"}}>Create a memorial</button> */}
                                <ModalCreateMemorial emptyState={true} />
                            </div>
                        </div>
                    </>
                }

                {!emptyState &&
                    <>
                        {/* <span className="flag-icon flag-icon-us"></span> */}
                        <div className="px-3 mb-3">

                            {/* Internationalisation */}                
                            <div className="reoccuring-donation-switch form-check form-switch temp">
                                <input className="form-check-input m-0" type="checkbox" role="switch" id="toggleLanguageSwitch" onClick={() => changeLanguage()} placeholder="" title=""/>
                                <label className="form-check-label" htmlFor="toggleLanguageSwitch"><img src={currentFlag} alt="" /></label>
                            </div>
                        </div>

                        <div className="d-flex gap-3">
                            <p className="fl-txt-home-subheading ps-3" style={{whiteSpace: "nowrap"}}>{ t("Home.Memorials.heading", {ns: "components"}) }</p>
                            <div className="home-memorials-heading-line">&nbsp;</div>
                        </div>

                        {/* style={{height: "calc(100dvh - 190px)"}} */}
                        <div className="d-flex flex-column justify-content-between">

                            {/* Memorials */}
                            {/* <div className="py-3 pe-0 h-100"> */}
                                <div className="home-memorials d-flex flex-column gap-3 p-3 h-100">
                                    {memorials}
                                    <ModalCreateMemorial emptyState={false} />
                                </div>
                            {/* </div> */}

                            {/* <div className="px-3">
                                
                            </div> */}
                        </div>

                        {/* --------------- Old Communities code - keeping for communities MVP --------------- */}

                        {/* <div className="p-3 mb-5">

                            
                            <p className="fl-txt-home-subheading mb-3">{ t("Home.Communities.heading", {ns: "components"}) }</p>
                            
                            <div className="communities-tile-large timeline-card d-flex flex-column p-3 pt-4">

                                <div id="discoverCommunities" className="carousel slide w-100">
                                    <div className="carousel-indicators" style={{right: "auto", marginLeft: "0"}}>
                                        <button id="discoverCommunitiesSlide1" type="button" data-bs-target="#discoverCommunities" data-bs-slide-to="0" className="indicator-initial active" aria-current="true" aria-label="" data-bs-interval="false" data-bs-touch="true" data-bs-ride="carousel"></button>
                                        <button type="button" data-bs-target="#discoverCommunities" data-bs-slide-to="1" aria-label="" data-bs-interval="false" data-bs-touch="true" data-bs-ride="carousel"></button>
                                        <button type="button" data-bs-target="#discoverCommunities" data-bs-slide-to="2" aria-label="" data-bs-interval="false" data-bs-touch="true" data-bs-ride="carousel"></button>
                                    </div>

                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="d-flex flex-column gap-2">
                                                <p>{ t("Home.Communities.Carousel.Slide1.title", {ns: "components"}) }</p>
                                                <p>{ t("Home.Communities.Carousel.Slide1.copy", {ns: "components"}) }</p>
                                            </div>
                                            <img src="/images/communities-img-1.png" className="timeline-image mt-2 mb-4" alt="" />
                                        </div>

                                        <div className="carousel-item">
                                            <div className="d-flex flex-column gap-2">
                                                <p>{ t("Home.Communities.Carousel.Slide2.title", {ns: "components"}) }</p>
                                                <p>{ t("Home.Communities.Carousel.Slide2.copy", {ns: "components"}) }</p>
                                            </div>
                                            <img src="/images/communities-img-2.png" className="timeline-image mt-2 mb-4" />
                                        </div>

                                        <div className="carousel-item">
                                            <div className="d-flex flex-column gap-2">
                                                <p>{ t("Home.Communities.Carousel.Slide3.title", {ns: "components"}) }</p>
                                                <p>{ t("Home.Communities.Carousel.Slide3.copy", {ns: "components"}) }</p>
                                            </div>
                                            <img src="/images/communities-img-3.png" className="timeline-image mt-2 mb-4" alt="" />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="btn fl-btn-white" style={{cursor: "pointer", userSelect: "none"}}>{ t("Home.Communities.addCommunities", {ns: "components"}) }</div>
                            </div>

                        </div>

                        <hr style={{borderTop: "10px solid white"}} />

                        
                        <div className="p-3">
                            <p className="fl-txt-home-subheading mb-3">Additional Components</p>

                            <div className="timeline-btn mb-3">
                                <img src="/images/fl-avatar-borderless.svg" className="mb-3" alt="" />
                                <p className="timeline-btn-title">{ t("Home.Memorials.addMemorial", {ns: "components"}) }</p>
                            </div>

                            <div className="timeline-btn mb-3">
                                <img src="/images/fl-communities-icon.svg" className="mb-3" alt="" />
                                <p className="timeline-btn-title">{ t("Home.Communities.addCommunities", {ns: "components"}) }</p>
                            </div>

                            <div className="home-community-tile">
                                <div className="d-flex align-items-center gap-3 p-3">
                                    <img src="/images/fl-avatar-min.svg" width="48" alt="" />
                                    <div className="home-community-tile-body">
                                        <p>Community Name</p>
                                        <p>Community Subtitle</p>
                                    </div>
                                </div>
                                <div className="home-community-tile-footer d-flex flex-column gap-1 align-items-center p-3">
                                    <img src="/images/fl-share.svg" className="" width="16" alt="" />
                                    <p>{ t("Home.Communities.shareCommunity", {ns: "components"}) }</p>
                                </div>
                            </div>

                        </div> */}
                    </>
                }
            </div>
        </>
    );
}