import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { LifeEventCreateType, MemorialType, PictureGalleryType, PictureUploadType } from "../AppType";
import { Modal } from "react-bootstrap";
import { ASSET_URL, fetchRecords, postFormData, postFormPictureFormData } from "../../utils/AppUtil";
import { ModalRememberDate } from "./ModalRememberDate";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import 'animate.css';
import AvatarEditor from "react-avatar-editor";
import { useSpring } from "@react-spring/web";
import { createUseGesture, dragAction, pinchAction } from "@use-gesture/react";
import imageCompression from "browser-image-compression";
import { GoogleMap, useJsApiLoader, Marker, Libraries } from '@react-google-maps/api';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

const containerStyle = {
    width: '100vw',
    height: '100dvh',
}

const center = {
    lat: -29.80,
    lng: 24.84
}

const libraries: Libraries = ["places"];

const useGesture = createUseGesture([dragAction, pinchAction]);

interface ModalProps {
    triggerLifeEvent: (value: string) => void;
    changeEvent: () => void;
    handleEventCreated: (eventType: string, status: boolean, error: string) => void;
}

export const CreateLifeEvent: React.FC<ModalProps> = ({triggerLifeEvent, changeEvent, handleEventCreated}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Date field refs
    const refEventDateDay = useRef<HTMLInputElement>(null!);
    const refEventDateMonth = useRef<HTMLInputElement>(null!);
    const refEventDateYear = useRef<HTMLInputElement>(null!);

    // Cropping & zooming refs
    const pinchRef = useRef<HTMLDivElement>(null!)
    const editorRef = useRef<AvatarEditor>(null!);
    const pictureInputRef = useRef<HTMLInputElement>(null!);

    // Form progression
    const [formStep, setFormStep] = useState<number>(0);
    const [formStepDate, setFormStepDate] = useState<boolean>(false);
    const [formSuccess, setFormSuccess] = useState<string>("");

    // Gallary & Editor states
    const [formStateImages, setFormStateImages] = useState<string[]>([]);
    const [newImage, setNewImage] = useState<File>();
    const [newImages, setNewImages] = useState<File[]>([]);
    const [zoom, setZoom] = useState<number>(1);
    const [addPhotos, setAddPhotos] = useState<boolean>(false);
    const [imageGallery, setImageGallery] = useState<React.ReactElement[]>();
    const [finalEdit, setFinalEdit] = useState<boolean>(false);

    // Modal states
    const [animation, setAnimation] = useState<string>("animate__animated animate__flipInY");
    const [charactersRemaining, setCharactersRemaining] = useState<number>(240);
    const [charactersRemainingText, setCharactersRemainingText] = useState<string>(" characters max");
    const [btnNext, setBtnNext] = useState<boolean>(true);
    const [btnNextAnimation, setBtnNextAnimation] = useState<string>("");
    const [btnSkip, setBtnSkip] = useState<boolean>(false);
    const [btnSkipAnimation, setBtnSkipAnimation] = useState<string>("");
    const [spinnerLoading, setspinnerLoading] = useState<boolean>(false);
    const [formProgressHide, setFormProgressionHide] = useState<string>("");

    // Date validation states
    const [invalidDate, setInvalidDate] = useState<boolean>(false);
    const [invalidDay, setInvalidDay] = useState<boolean>(false);
    const [invalidMonth, setInvalidMonth] = useState<boolean>(false);
    const [invalidYear, setInvalidYear] = useState<boolean>(false);
    const invalidBorder: string = '3px solid #DC3545';

    // Map states
    const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);
    const [map, setMap] = React.useState<google.maps.Map | null>(null);
    const [markerPosition, setMarkerPosition] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
    const [mapZoom, setMapZoom] = useState<number>(13);
    const [searchOpen, setsearchOpen] = useState<boolean>(false);
    const [searchSelected, setsearchSelected] = useState<boolean>(false);
    const [mapClose, setMapClose] = useState<boolean>(false);

    const [address, setAddress] = useState("");

    // formState for loading image to gallery
    const initForm: PictureUploadType = {
        memorialId: memorialData.memorialId,
        file: null
    }
    const [formState, setFormState] = useState<PictureUploadType>(initForm);

    // formState for the life event being created
    const initFormLifeEvent: LifeEventCreateType = {
        title: "",
        message: "",
        eventDate: "",
        eventDateDay: "",
        eventDateMonth: "",
        eventDateYear: "",
        locations: []
    }
    const [formStateLifeEvent, setFormStateLifeEvent] = useState<LifeEventCreateType>(initFormLifeEvent);

    // Initial settings for touch-zoom functionality
    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: zoom,
        rotateZ: 0,
    }));

    // useEffect to prevent default event behaviour for touch actions on component mount
    useEffect(() => {
        const handler = (e: Event) => e.preventDefault();
        document.addEventListener('gesturestart', handler);
        document.addEventListener('gesturechange', handler);
        document.addEventListener('gestureend', handler);

        // return runs cleanup on unmount to remove event listeners
        return () => {
            document.removeEventListener('gesturestart', handler);
            document.removeEventListener('gesturechange', handler);
            document.removeEventListener('gestureend', handler);
        };
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, []);

    // Google Maps
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDX5iQx8lsEcck9KXBSRRLNh7oIwN3hF0U',
        libraries: libraries
    })

    const onLoad = React.useCallback(function callback(map: google.maps.Map) {
        setMap(map);
        map.setZoom(mapZoom)
    }, []);
    
    const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
        setMap(null);
    }, []);

    // const handleMapClick = (event: google.maps.MapMouseEvent) => {
    //     if (event.latLng) {
    //         setMarkerPosition({
    //             lat: event.latLng.lat(),
    //             lng: event.latLng.lng()
    //         });

    //         // setFormState((formState) => ({
    //         //     ...formState, 
    //         //     locationLat: event.latLng.lat(),
    //         //     locationLng: event.latLng.lng(),
    //         //     locationDesc: address
    //         // }));
    //     }
    // };

    const handleLocationSelect = async (value: any) => {
        const results = await geocodeByAddress(value);
        const coords = await getLatLng(results[0]);

        setAddress(value);
        setMarkerPosition({lat: coords.lat, lng: coords.lng});
        setUserLocation({lat: (coords.lat - 0.015), lng: coords.lng});
    }

    // Fetch gallery data
    useEffect(() => {
        fetchRecords(commonData.token, `picture/memorial/${formState.memorialId}`, processData);
    }, [memorialData, newImages, formStateImages]);

    // Process gallery data
    const processData = (responseJson: PictureGalleryType[]) => {
        setImageGallery(buildImageGallery(responseJson));
    }

    // Build gallery JSX
    const buildImageGallery = (data: PictureGalleryType[]): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;
    
        data.forEach((picture: PictureGalleryType) => {
            const pictureSrc: string = `${ASSET_URL}/public/picture/${picture.pictureId}`;
            const isSelected = formStateImages.includes(picture.pictureId);
            const index = formStateImages.indexOf(picture.pictureId);
    
            result.unshift(
                <div key={key} style={{ position: 'relative' }}>
                    <img
                        src={pictureSrc}
                        onClick={() => addImageToEvent(picture.pictureId)}
                        className={isSelected ? 'selected' : ''}
                    />
                    {isSelected && (
                        <div className="number-indicator">
                            {index + 1}
                        </div>
                    )}
                </div>
            );
            key++;
        });
    
        return result;
    }

    // Build new-images gallery JSX
    const buildNewImagesGallery = (): React.ReactElement[] => {
        return newImages.map((file, index) => {
            const imageUrl = URL.createObjectURL(file);
            return <img key={`new-${index}`} src={imageUrl} onClick={() => selectImageFromFile(file)} style={{ border: "1px solid yellow" }} />;
        });
    }

    // Select image from 'local' gallery
    const selectImageFromFile = (file: File) => {
        setNewImage(file);
    }

    // Auto select next local gallery image
    const selectNextImage = () => {
        if (newImages) {
            console.log(newImages[0] + "................")
            setNewImage(newImages[0]);
        }
        console.log(newImage)
    }

    // Process new images added to 'local' gallery
    const processNewFiles = (files: File[]) => {
        setNewImages((prevNewImages) => [...prevNewImages, ...files]);
        setNewImage(files[0]);
    }

    // Add selected image to the memory
    const addImageToEvent = (pictureId: string) => {
        setFormStateImages((prevFormStateImages) => {
            const updatedFormStateImages = prevFormStateImages.includes(pictureId)
                                           ? prevFormStateImages.filter(id => id !== pictureId)
                                           : [...prevFormStateImages, pictureId];
            return updatedFormStateImages;
        });
    }

    // Build image array used for the memory
    const buildImageArray = (): React.ReactElement[] => {
        return formStateImages.map((id, index) => {
            const imageUrl: string = `${ASSET_URL}/public/picture/${id}`;
            return <img key={`new-${index}`} src={imageUrl} style={{borderRadius: "8px", marginRight: "12px"}} width={124} height={124} />;
        });
    }

    // Scroll zoom for pc users
    const scaleStep = 0.1;
    document.getElementById('selectedImageContainer')?.addEventListener('wheel', (event) => {
        event.preventDefault();
        
        if (event.deltaY < 0) {
            setZoom(zoom + scaleStep);
        } else {
            setZoom(zoom - scaleStep);
        };
    });

    // Deactivate DOM zooming to allow uninterupted image zooming
    const deactivateZoomDiv = document.getElementById('deactivateZoom');
    deactivateZoomDiv?.addEventListener('touchmove', function(event) {
        const touchEvent = event as TouchEvent & { scale: number };
        if (touchEvent.scale !== 1) {
            event.preventDefault();
        }
    }, {passive: false});

    // Touch-pinch-zoom actions
    useGesture(
        {
            onDrag: ({pinching, cancel, offset: [x, y], ...rest}) => {
                if (pinching) return cancel();
                api.start({ x, y });
            },
            onPinch: ({offset: [s], first}) => {
                if (first) {
                    const {width, height, x, y} = pinchRef.current!.getBoundingClientRect();
                    const tx = x + width / 2;
                    const ty = y + height / 2;
                    api.start({ x: -tx, y: -ty });
                }
                setZoom(s);
            },
        },
        {
            target: pinchRef,
            drag: {from: () => [style.x.get(), style.y.get()]},
            pinch: {scaleBounds: {min: 0.25, max: 5}, rubberband: false},
        }
    );

    // Validation (day)
    const validateDay = (value: string): string => {
        let numberValue: number = parseInt(value, 10);

        if (isNaN(numberValue) || /\D/.test(value)) {
            setInvalidYear(true);
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            setInvalidDay(false);
            return '0' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            setInvalidDay(false);
            return numberValue.toString();

        } else {
            setInvalidDay(true);
            return 'invalid';
        }
    }

    // Validation (month)
    const validateMonth = (value: string): string => {
        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            setInvalidYear(true);
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            setInvalidMonth(false);
            return '0' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            setInvalidMonth(false);
            return numberValue.toString();

        } else {
            setInvalidMonth(true);
            return 'invalid';
        }
    }

    // Validation (year)
    const validateYear = (value: string): string => {

        let numberValue: number = parseInt(value, 10);

        if (isNaN(numberValue) || /\D/.test(value)) {
            setInvalidYear(true);
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            setInvalidYear(false);
            return '000' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            setInvalidYear(false);
            return '00' + numberValue.toString();

        } else if (numberValue >= 100 && numberValue < 1000) {
            setInvalidYear(false);
            return '0' + numberValue.toString();

        } else if (numberValue >= 1000 && numberValue < 10000) {
            setInvalidYear(false);
            return numberValue.toString();

        } else {
            setInvalidYear(true);
            return 'invalid';
        }
    }

    // Form change (non date fields)
    const handleFormChange = (e: React.ChangeEvent<any>) => {

        if (e.target.name === "message") {
            const value = e.target.value;

            if (value.length <= 240) {
                setCharactersRemaining(240 - value.length);

                if (value.length < 240) {
                    setCharactersRemainingText(" characters remaining");
                } else {
                    setCharactersRemainingText(" characters max");
                }

                setFormStateLifeEvent(() => ({
                    ...formStateLifeEvent,
                    [e.target.name]: e.target.value
                }));
            }
        }

        if (e.target.name === "title") {
            setFormStateLifeEvent(() => ({
                ...formStateLifeEvent,
                [e.target.name]: e.target.value
            }));
        }

        if (e.target.type === "file" && e.target.files != null) {
            const filesArray: File[] = Array.from(e.target.files);
            processNewFiles(filesArray);
        }
    }

    // Form change (date fields)
    const handleFormChangeDate = (e: React.ChangeEvent<any>) => {

        if (e.target.type === "text") {

            if (e.target.id === "eventDateYear") {
                setFormStateLifeEvent((formStateLifeEvent) => ({
                    ...formStateLifeEvent,
                    [e.target.id]: validateYear(e.target.value)
                }));

                if (e.target.value.length === 4 && !formStepDate) {
                    setFormStepDate(true);
                    setTimeout(() => {
                        if (refEventDateMonth.current) {
                            refEventDateMonth.current.focus();
                        }
                    }, 500);
                } else if (e.target.value.length === 4 && formStepDate) {
                    refEventDateMonth.current.focus();
                }
            }

            if (e.target.id === "eventDateMonth") {
                setFormStateLifeEvent((formStateLifeEvent) => ({
                    ...formStateLifeEvent,
                    [e.target.id]: validateMonth(e.target.value)
                }));

                if (e.target.value.length === 2) {
                    refEventDateDay.current.focus();
                }
            }

            if (e.target.id === "eventDateDay") {
                setFormStateLifeEvent((formStateLifeEvent) => ({
                    ...formStateLifeEvent,
                    [e.target.id]: validateDay(e.target.value)
                }));
            }
        }
    }

    // Unknown date - change from Life Event to Memory
    const handleChildClick = () => {
        handleClickBackClose();
        changeEvent();
    }

    const handleClickNext = () => {

        if (formStep === 1 && !formStepDate) {
            setFormStepDate(true);
        } else if (formStep === 1 && formStepDate) {

            if (formStateLifeEvent.eventDateDay != 'invalid' && formStateLifeEvent.eventDateMonth != 'invalid' && formStateLifeEvent.eventDateYear != 'invalid') {
                setFormStateLifeEvent((formStateLifeEvent) => ({
                    ...formStateLifeEvent, 
                    eventDate: formStateLifeEvent.eventDateYear + "-" + formStateLifeEvent.eventDateMonth + "-" + formStateLifeEvent.eventDateDay
                }));

                setFormStep(formStep + 1);
            } else {
                setInvalidDate(true);
            }

        } else if (formStep === 2) {
            setBtnNextAnimation('animate__animated animate__fadeOutDown animate__faster');
            setTimeout(() => {
                setBtnNext(false);
                setBtnSkip(true);
                setBtnSkipAnimation('animate__animated animate__fadeInUp animate__faster');
            }, 500);
            setFormStep(formStep + 1);
        } else {
            setFormStep(formStep + 1);
        }
    }

    // Navigate form backwards
    const handleClickBack = () => {
        if (formStep === 3) {
            setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
            setTimeout(() => {
                setBtnSkip(false);
                setBtnNext(true);
                setBtnNextAnimation('animate__animated animate__fadeInUp animate__faster');
            }, 500);
            setFormStep(formStep - 1);

        } else if (formStep === 4) {
            setBtnSkipAnimation('animate__animated animate__fadeInUp animate__faster');
            setBtnSkip(true);
            setFormProgressionHide("");
            setFormStep(formStep - 1);

        } else if (formStep === 5) {
            setBtnNextAnimation('animate__animated animate__fadeOutDown animate__faster');
            setAddPhotos(false);
            setFormProgressionHide("");

            setTimeout(() => {
                setBtnSkipAnimation('animate__animated animate__fadeInUp animate__faster');
                setBtnNext(false);
                setBtnSkip(true);
            }, 500);
            setFormStep(3);
        } else if (formStep === 6) {
            setMapClose(true);
            setTimeout(() => {
                setFormStep(formStep - 1);
                setMapClose(false);
            }, 700);
        } else {
            setFormStep(formStep - 1);
        }
    }

    const handleClickBackClose = () => {
        setAnimation("animate__animated animate__flipOutY");
        triggerLifeEvent("animate__animated animate__flipInY");
    }

    // Skip adding media - go to final screen
    const handleSkip = () => {
        setAddPhotos(false);
        setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
        setTimeout(() => {
            setFormProgressionHide("animate__animated animate__fadeOutLeft animate__faster");
            setBtnSkip(false);
            setFormStep(5);
        }, 250);
    }

    // Add images media option selected
    const handleAddPhotos = () => {
        setAddPhotos(true);
        setFormProgressionHide("animate__animated animate__fadeOutLeft animate__faster");
        setFormStep(4);

        setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
        setTimeout(() => {
            setBtnSkip(false);
        }, 500);
    }
    
    // TODO - Scan photos media option selected
    const handleScanPhotos = () => {

    }
    
    // TODO - Add videos media option selected
    const handleAddVideos = () => {

    }

    // Add location option selected
    const handleAddLocation = () => {
        setFormStep(6);
    }

    // Add selected location to life event
    const handleLocationAdded = () => {
        setFormStateLifeEvent((formStateLifeEvent) => ({
            ...formStateLifeEvent,
            locations: [
              ...formStateLifeEvent.locations,
              {
                latitude: markerPosition.lat,
                longitude: markerPosition.lng,
                description: address
              }
            ]
        }));
        handleClickBack();
    }

    // Add selected images to the formState
    const handleAddImageArray = () => {
        setFormStateLifeEvent((prevState) => ({
            ...prevState,
            pictureIds: formStateImages
        }));

        setFormStep(formStep + 1);
    }

    // Handle image cropping
    const handleCrop = async (): Promise<Blob> => {
        const canvasImage: HTMLCanvasElement = editorRef.current.getImage();
    
        return new Promise((resolve, reject) => {
            canvasImage.toBlob((blob: any) => {
                if (!blob) {
                    reject(new Error("Failed to convert canvas to Blob"));
                    return;
                }
    
                let name = "";
                if (pictureInputRef?.current?.files?.length) {
                    name = pictureInputRef.current.files[0].name;
                }
    
                setFormState((formState): PictureUploadType => {
                    const form = { ...formState, file: blob };
                    return form;
                });
    
                resolve(blob);
            });
        });
    }

    // Handle image compression
    const handleCompression = async (file: any): Promise<Blob> => {
        console.log('originalFile instanceof Blob', file instanceof Blob); // true
        console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
    
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };
    
        try {
            const compressedFile = await imageCompression(file, options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    
            return compressedFile;
        } catch (error: any) {
            console.log(error.message);
            throw error;
        }
    }

    // Truncate title
    const truncate = (text: string) => {
        return text.length > 30 ? text.substring(0, 30) + "..." : text;
    }

    // Post image data to gallery
    const handlePostPicture = async (compressedFile: Blob, fileName: string) => {
        console.log('Posting Form State:', formState);
        console.log('Posting File:', compressedFile);
    
        const formData = new FormData();
        formData.set("memorialId", formState.memorialId);
        formData.set("file", compressedFile);
    
        postFormPictureFormData(
            commonData.token,
            'picture',
            formData,
            (responseData: any) => {
                console.log("Multi-post Success", responseData);
                setNewImages((prevNewImages) => prevNewImages.filter((file) => file.name !== fileName));
            },
            (response: any) => { // error
                console.log("Multi-post Fail", response);
            }
        );
    }

    // Handle click to crop, compress then post images to gallery
    const handleClick = async (): Promise<void> => {
        try {
            setspinnerLoading(true);
            const croppedBlob = await handleCrop();
            console.log('Cropping completed successfully.');
            console.log('CROPPED:', croppedBlob);
    
            const compressedBlob = await handleCompression(croppedBlob);
            console.log('Compression completed successfully.');
            console.log('COMPRESSED:', compressedBlob);
    
            // Directly call handlePost and pass the compressed file and its name
            await handlePostPicture(compressedBlob, newImage!.name);

            newImages.shift()
            selectNextImage();
    
            setspinnerLoading(false);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    // Activate final screen input fields
    const handleFinalEdit = () => {
        setFinalEdit(c => !c);
    }

    //Open & close search
    const handleSearchOpen = () => {
        setsearchOpen(c => !c);
    }

    const handleSearchInput = () => {
        if (!searchOpen) {
            setsearchOpen(true);
            setsearchSelected(false);
        }
    }

    const handleSearchSelect = () => {
        if (searchOpen) {
            setsearchOpen(false);
            setTimeout(() => {
                setsearchSelected(true);
            }, 1000);
        }
    }

    // Post form data to create Life Event
    const handlePost = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        postFormData(
            commonData.token,
            `memorial/${memorialData.memorialId}/life_event`,
            formStateLifeEvent,
            (response: any) => {
                handleEventCreated('Life Event', true, '');
                setFormSuccess("");
                setFormStep(7);
            },
            (error: any) => { // error
                handleEventCreated('Life Event', false, error);
                setFormSuccess(error);
                setFormStep(7);
            }
        );
    }

    return (
        <>
            <div className={`form-content h-100 ${animation}`} style={{backgroundColor: "#00222F", zIndex: "999"}}>

                <div className="d-flex justify-content-center p-3">
                    {formStep !== 7 &&
                        <>
                            {formStep === 0 ?
                                <img src="/images/fl-arrow-back.svg" onClick={handleClickBackClose} className="form-progress-back"/>
                                :
                                <img src="/images/fl-arrow-back.svg" onClick={handleClickBack} className="form-progress-back"/>
                            }
                        </>
                    }
                    <p className="modal-title">New Life Event</p>
                    <button className="btn btn-modal-header" onClick={handleClickBackClose} style={{zIndex: 999}} />
                </div>

                <div className={`form-progress form-progress-absolute ${formProgressHide}`} style={{top: "100px"}}>
                    <img src={`/images/fl-progress-book.svg`} className={`form-progress-current form-progress-${formStep}`} />
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                </div>

                <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep < 0 ? 'prev' : 'next')}`} style={{padding: "170px 16px 16px"}}>
                    <p className="modal-heading mb-4">What’s this life event called?</p>
                    
                    <div className="login-form">
                        <div className="mb-4">
                            <label htmlFor="title" className="form-label">Title</label>
                            <input id="title" name="title" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter a short title for this event" />
                        </div>
                    </div>
                </div>

                <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep < 1 ? 'prev' : 'next')}`} style={{padding: "170px 16px 16px"}}>
                    <div className="d-flex flex-column gap-2 mb-4">
                        <p className="modal-heading">When did <i>“{formStateLifeEvent.title}”</i> happen?</p>
                    </div>
                    
                    <div className="login-form">
                        <div className="d-flex gap-3 mb-4">
                            
                            <div className="row w-100">
                                <div className="form-group col-4">
                                    <label htmlFor="eventDateYear" className="form-label">Year*</label>
                                    <input ref={refEventDateYear} id="eventDateYear" name="eventDateYear" type="text" onChange={handleFormChangeDate} className="form-control" placeholder="YYYY" style={{border: invalidYear ? invalidBorder : ""}} />
                                </div>
                                
                                {formStepDate && 
                                    <>
                                        <div className="form-group col-4 animate__animated animate__fadeInRight">
                                            <label htmlFor="eventDateMonth" className="form-label">Month</label>
                                            <input ref={refEventDateMonth} id="eventDateMonth" name="eventDateMonth" type="text" onChange={handleFormChangeDate} className="form-control" placeholder="MM" style={{border: invalidMonth ? invalidBorder : ""}} />
                                        </div>

                                        <div className="form-group col-4 animate__animated animate__fadeInRight">
                                            <label htmlFor="eventDateDay" className="form-label">Day</label>
                                            <input ref={refEventDateDay} id="eventDateDay" name="eventDateDay" type="text" onChange={handleFormChangeDate} className="form-control" placeholder="DD" style={{border: invalidDay ? invalidBorder : ""}} />
                                        </div>
                                    </>
                                }

                                {invalidDate &&
                                    <div className="invalid-form-entry animate__animated animate__zoomIn">Please enter a valid date</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`form-screen form-content form-content-${formStep === 2 ? 'current' : (formStep < 2 ? 'prev' : 'next')}`} style={{padding: "170px 16px 16px"}}>
                    <div className="form-floating">
                        <textarea id="message" name="message" value={formStateLifeEvent.message} onChange={handleFormChange} maxLength={240} className="form-control memory-message-input pt-0" placeholder="Write a description of the life event" />
                        <p className="memory-message-limit">{charactersRemaining} {charactersRemainingText}</p>
                    </div>
                </div>
                
                <div className={`form-screen form-content form-content-${formStep === 3 ? 'current' : (formStep < 3 ? 'prev' : 'next')}`} style={{padding: "170px 16px 16px"}}>
                    <p className="modal-heading mb-4">Upload some photos or videos</p>

                    <div className="d-flex flex-column">
                        <div className="image-upload-menu-item" onClick={handleAddPhotos}>
                            <div className="d-flex gap-3">
                                <img src="images/camera-icon.svg" width={24} />
                                <p className="event-add-assets">Add photo/s</p>
                            </div>
                        </div>

                        <div className="image-upload-menu-item" onClick={handleScanPhotos}>
                            <div className="d-flex gap-3">
                                <img src="images/scan-icon.svg" width={24} />
                                <p className="event-add-assets">Scan a photo</p>
                            </div>
                        </div>

                        <div className="image-upload-menu-item" onClick={handleAddVideos}>
                            <div className="d-flex gap-3">
                                <img src="images/recorder-icon.svg" width={24} />
                                <p className="event-add-assets">Add Video/s</p>
                            </div>
                            <div className="premium-badge">Premium</div>
                        </div>
                        
                    </div>
                </div>

                <div className={`form-screen form-content form-content-${formStep === 4 ? 'current' : (formStep < 4 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>

                    {addPhotos === true &&
                        <>
                            <div className="event-photo-uploading">
                                <div ref={pinchRef} id="deactivateZoom" style={{height: "100vw"}}>
                                    <div className="d-flex justify-content-center">
                                        <div className="image-canvas-grid" />

                                        {spinnerLoading &&
                                            <div className="image-canvas-loading animate__animated animate__fadeIn animate__faster">
                                                <img src="images/creating-memorial.svg" />
                                                <p className="loading-ellipsis">Uploading Image</p>
                                            </div>
                                        }

                                        <div className="memorial-img-crop-wrapper d-flex justify-content-center align-items-center">
                                            <div id="selectedImageContainer" className="memorial-img-crop-preview">
                                                {newImage &&
                                                    <AvatarEditor
                                                        ref={editorRef}
                                                        image={newImage}
                                                        border={0}
                                                        borderRadius={0}
                                                        scale={zoom}
                                                        rotate={0}
                                                        style={{
                                                            transform: `translate(${style.x}px, ${style.y}px) scale(${style.scale}) rotate(${style.rotateZ}deg)`,
                                                            touchAction: "pinch-zoom",
                                                            width: "100vw",
                                                            height: "100vw"
                                                        }}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="image-gallery-wrapper d-flex flex-column w-100" style={{position: "relative"}} >
                                    <div className="image-upload-wrapper justify-content-evenly d-flex p-3">
                                        <label className="btn btn-image-upload">
                                            <input ref={pictureInputRef} id="file" name="file" type="file" accept="image/*" multiple onChange={handleFormChange} />
                                            <img src="/images/fl-plus-lg.svg" style={{marginBottom: "2px"}} /> Upload photos
                                        </label>
                                        {newImage && <button className="btn btn-image-upload" onClick={handleClick}>Add to gallery</button>}
                                        
                                    </div>
                                    
                                    <div className="image-gallery d-flex flex-row flex-wrap">
                                        {/* <div className={imageGalleryLoading}><img src="/images/fl-logo-small.svg" className={imageGalleryLoadingLogo} /></div> */}
                                        {buildNewImagesGallery()}
                                        {imageGallery}
                                    </div>
                                    
                                </div>


                            </div>
                        </>
                    }

                    {formStateImages && formStateImages.length !== 0 &&
                        <div className="fl-btn-group-modal-bottom">
                            <div className="image-gallery-fade animate__animated animate__slideInUp animate__faster"></div>
                            <button className="btn fl-btn-modal-bottom animate__animated animate__slideInUp animate__faster" onClick={handleAddImageArray} type="button">Add</button>
                        </div>
                    }
                </div>

                <div className={`form-screen form-content form-content-${(formStep === 5 || formStep === 6) ? 'current' : (formStep < 5 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                    <div className="d-flex justify-content-between gap-3">
                        {finalEdit ?
                            <div className="login-form w-100">
                                <input
                                    id="title"
                                    name="title"
                                    type="text"
                                    onChange={handleFormChange}
                                    className="form-control"
                                    value={formStateLifeEvent.title}
                                    placeholder="Enter a short title for this event"
                                    style={{height: '76px', fontSize: '1.5rem'}}
                                />
                            </div>:
                            <p className="modal-heading w-100">{truncate(formStateLifeEvent.title)}</p>
                        }
                        
                        <img src="/images/fl-btn-pen.svg" onClick={handleFinalEdit} width={32} height={32} style={{cursor: 'pointer'}} />
                    </div>

                    <p className="life-event-create-date" style={{height: "24px"}}>{formStateLifeEvent.eventDateDay}&nbsp;
                        {(formStateLifeEvent.eventDateMonth === "1" || formStateLifeEvent.eventDateMonth === "01") && "January"}
                        {(formStateLifeEvent.eventDateMonth === "2" || formStateLifeEvent.eventDateMonth === "02") && "February"}
                        {(formStateLifeEvent.eventDateMonth === "3" || formStateLifeEvent.eventDateMonth === "03") && "March"}
                        {(formStateLifeEvent.eventDateMonth === "4" || formStateLifeEvent.eventDateMonth === "04") && "April"}
                        {(formStateLifeEvent.eventDateMonth === "5" || formStateLifeEvent.eventDateMonth === "05") && "May"}
                        {(formStateLifeEvent.eventDateMonth === "6" || formStateLifeEvent.eventDateMonth === "06") && "June"}
                        {(formStateLifeEvent.eventDateMonth === "7" || formStateLifeEvent.eventDateMonth === "07") && "July"}
                        {(formStateLifeEvent.eventDateMonth === "8" || formStateLifeEvent.eventDateMonth === "08") && "August"}
                        {(formStateLifeEvent.eventDateMonth === "9" || formStateLifeEvent.eventDateMonth === "09") && "September"}
                        {formStateLifeEvent.eventDateMonth === "10" && "October"}
                        {formStateLifeEvent.eventDateMonth === "11" && "November"}
                        {formStateLifeEvent.eventDateMonth === "12" && "December"}&nbsp;
                        {formStateLifeEvent.eventDateYear}
                    </p>

                    <div className="event-images-preview no-scrollbar">
                        {buildImageArray()}
                        <div className="event-additional-images" onClick={handleAddPhotos} style={{cursor: 'pointer'}}><img src="/images/fl-plus-lg.svg" /></div>
                    </div>

                    {finalEdit ?
                        <div className="form-floating">
                            <textarea
                                id="message"
                                name="message"
                                value={formStateLifeEvent.message}
                                onChange={handleFormChange}
                                maxLength={240}
                                className="form-control memory-message-input final-edit pt-0"
                                placeholder="Write a description of the memory"
                                style={{backgroundColor: 'rgba(255, 255, 255, 0.0509803922)', border: '2px solid #40626F'}}
                            />
                            <p className="memory-message-limit">{charactersRemaining} {charactersRemainingText}</p>
                        </div>:
                        <p className="memory-preview-message">{formStateLifeEvent.message}</p>
                    }

                    {formStateLifeEvent.locations.length === 0 &&
                        <div className="event-additional-item" onClick={handleAddLocation}>
                            <div className="d-flex gap-3">
                                <img src="images/fl-map-pin-24.svg" width={24} />
                                <p className="event-add-assets">Add a location</p>
                            </div>
                        </div>
                    }
                    
                    {formStateLifeEvent.locations.length !== 0 &&
                        <div className="event-locations">
                            <>
                                {formStateLifeEvent.locations.map((location, index) => (
                                    <div key={index} className="life-event-location-pill d-flex gap-2">
                                        <img src="/images/fl-map-pin.svg" />
                                        <p>{location.description}</p>
                                    </div>
                                ))}
                                <div className="life-event-location-pill d-flex gap-2" onClick={handleAddLocation}>
                                    <img src="/images/fl-map-pin.svg" />
                                    <p>Add location</p>
                                </div>
                            </>
                        </div>
                    }
                    
                    <button className={`btn fl-btn-modal-bottom`} onClick={handlePost} style={{position: "absolute", bottom: 16, width: "calc(100% - 32px)", zIndex: "999"}}>Save Event</button>
                </div>

                <div className={`form-screen form-content form-content-${formStep === 7 ? 'current' : (formStep < 7 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>

                    <div className="d-flex flex-column h-100">
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center h-100">
                            <img src="/images/fl-login-tick.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>{formSuccess === "" ? 'Life Event added' : 'Failed'}</p>
                                <p>{formSuccess}</p>
                            </div>
                        </div>

                        <button onClick={handleClickBackClose} className="btn fl-btn-modal-bottom">OK</button>
                    </div>

                </div>

                {btnNext &&
                    <div className="fl-btn-group-modal-bottom">
                        <button className={`btn fl-btn-modal-bottom ${btnNextAnimation}`} onClick={handleClickNext}>Next</button>
                        {formStep === 1 &&
                            <div className="d-flex justify-content-center w-100">
                                <ModalRememberDate onButtonClick={handleChildClick} />
                            </div>
                        }
                    </div>
                }
                {btnSkip &&
                    <>
                        <button className={`btn-modal-footer-skip ${btnSkipAnimation}`} onClick={handleSkip}>Skip</button>
                    </>
                }

                {isLoaded && formStep === 6 ?
                    <>
                        <div className={`full-screen-div animate__animated animate__slide${mapClose ? 'Out' : 'In'}Right animate__fast`} style={{marginTop: "-50px"}}>

                            <GoogleMap 
                                mapContainerStyle={containerStyle}
                                center={userLocation || center}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                // onClick={handleMapClick}
                                options={{disableDefaultUI: true,}}
                            >
                                {markerPosition?.lat !== 0 && markerPosition?.lng !== 0 && (
                                    <Marker position={markerPosition} />
                                )}
                            </GoogleMap>

                        </div>

                        {/* ${modalSuccessMenu ? "animate__slideOutDown" : "animate__delay-1s"} */}
                        <div className={`map-search ${searchOpen && "active"} pt-0 animate__animated animate__slide${mapClose ? 'OutDown' : 'InUp'} `} style={{height: 'auto'}}>
                            <div className="d-flex justify-content-center align-items-center" onClick={handleSearchOpen}>
                                <div className="modal-account-drawer-close"></div>
                            </div>

                            <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleLocationSelect}>
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div key={getSuggestionItemProps.name}>
                                    <input {...getInputProps({ placeholder: 'Search places', className: 'location-search-input',})} onClick={handleSearchInput} />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div className="autocomplete-results">Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                            
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                            ? { cursor: 'pointer' }
                                            : {  };

                                            return (
                                                <div {...getSuggestionItemProps(suggestion, {className,style})}>
                                                    <div className="d-flex justify-content-between gap-2 py-1">
                                                        <img src="/images/fl-map-pin-24.svg"  />
                                                        <span className="autocomplete-results w-100" onClick={handleSearchSelect} style={{overflow: "hidden"}}>
                                                            <b>{suggestion.formattedSuggestion.mainText}</b>
                                                            <br/>
                                                            <span style={{whiteSpace: "nowrap"}}>{suggestion.formattedSuggestion.secondaryText}</span>
                                                        </span>
                                                        <img src="/images/arrow-up-left.svg" />
                                                    </div>
                                                    <hr className="autocomplete-hr" />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                )}
                            </PlacesAutocomplete>

                            {/* {searchSelected && <button id="submitBtn" type="button" className="btn fl-btn-large w-100 animate__animated animate__fadeInUp animate__faster mb-2" onClick={handlePost}>Done</button>} */}
                            {!searchOpen &&
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-modal-footer" onClick={handleLocationAdded}>Done</button>
                                </div>
                            }
                        </div>
                    </>
                    : <></>
                }


            </div>
        </>
    );
}





